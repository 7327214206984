import React, { useEffect, useState } from "react";
import Api from "./components/api/api";
import Login from "./components/Login";
import Loading from "./components/Loading";
import Lock from "./components/Lock";
import Header from "./components/Header";
import PopUp from "./components/PopUp";
import Menu from "./components/Menu";
import Settings from "./components/Settings";
import Confirm from "./components/confirm";
import jwt_decode from "jwt-decode";
import { MapContainer } from "react-leaflet";
import "./css/App.css";
import "./css/Login.css";
import "./css/Header.css";
import "./css/Loading.css";
import "./css/Lock.css";
import "./css/PopUp.css";
import "./css/Menu.css";
import "./css/Settings.css";
import Map from "./components/Map";
import { shopIcon } from "./components/icons/shopIcon";
import { orderIcon } from "./components/icons/orderIcon";
import Sidebar from "./components/Sidebar";
import AssignmentPopUp from "./components/AssignmentPopUp";
import Socket from "./Socket";
import { io } from "socket.io-client";

export const AppContext = React.createContext();

const hostname = window.location.hostname;
let socketUrl = false;
if (hostname.includes("localhost")) {
	//socketUrl = "https://ws.yellgo.cloud:3200";
	socketUrl = "https://ws.devaws.yellgo.idspace.it:3000";
}
else if (hostname.includes(".demo.")) socketUrl = "https://ws.yellgo.cloud:3100";
else if (hostname.includes(".dev.")) socketUrl = "https://ws.devaws.yellgo.idspace.it:3000";
else if (hostname.includes(".devws.")) socketUrl = "https://ws.devaws.yellgo.idspace.it:3000";
else socketUrl = "https://ws.yellgo.cloud";
const socket = io(socketUrl);

function App() {
	const [lock, setLock] = useState(false);
	const [loading, setLoading] = useState(false);
	const [settings, setSettings] = useState(false);
	const [apiSettings, setApiSettings] = useState(false);
	const [menu, setMenu] = useState(false);
	const [settingsVisible, setSettingsVisible] = useState(false);
	const [user, setUser] = useState(false);
	const [shops, setShops] = useState(false);
	const [shop, setShop] = useState(
		localStorage.getItem("tracking-shop")
			? JSON.parse(localStorage.getItem("tracking-shop"))
			: false
	);
	const [filteredCategories, setFilteredCategories] = useState(false);
	const [drivers, setDrivers] = useState(false);
	const [popup, setPopup] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [iframeMode, setIframeMode] = useState(false);
	const [orders, setOrders] = useState([]);
	const [markers, setMarkers] = useState([]);
	const [segments, setSegments] = useState([]);
	const [map, setMap] = useState(null);
	const [assignmentPopUp, setAssignmentPopUp] = useState(false);
	const [config, setConfig] = useState(
		localStorage.getItem("tracking-config")
			? JSON.parse(localStorage.getItem("tracking-config"))
			: {
				mode: "remote",
				line_type: { name: "Linea", id: "line" },
				map_tiles: { name: "Base", id: "base" },
			}
	);
	const queryParams = new URLSearchParams(window.location.search);

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		if (user && apiSettings) loadData();
	}, [user, apiSettings]);

	useEffect(() => {
		if (user && shops && apiSettings) {
			loadDrivers();
			loadOrders();
		}
	}, [user, shops, shop, apiSettings]);

	useEffect(() => {
		if (shops) generateMarkers();
	}, [orders, shops]);

	useEffect(() => {
		if (drivers && shops) generateSegments();
	}, [orders, drivers, shops, shop]);

	async function init() {
		console.log("init");
		setLock(true);
		Api.setBaseUrl(config);
		await loadSettings();
		await restoreUser();
		setLock(false);
	}

	async function loadData() {
		console.log("loadData");
		setLock(true);
		await loadShops();
		setLock(false);
	}

	async function restoreUser() {
		console.log("restoreUser");
		if (queryParams.get("user_id")) {
			setIframeMode(true);
			const id = queryParams.get("user_id");
			const refresh = await Api.autoLogin(id);
			if (refresh.success == 0) alert(refresh.error);
			else {
				localStorage.setItem("pos-user", refresh.token);
				const decoded = jwt_decode(refresh.token);
				const response = await Api.getProtected("/users/get/" + decoded.user_id + "/");
				if (response.success == 0) alert(response.error);
				else setUser(response.data);
			}
		} else {
			const token = localStorage.getItem("pos-user");
			if (token) {
				const refresh = await Api.refreshToken(token);
				if (refresh.success == 0) alert(refresh.error);
				else {
					localStorage.setItem("pos-user", refresh.token);
					const decoded = jwt_decode(refresh.token);
					const response = await Api.getProtected("/users/get/" + decoded.user_id + "/");
					if (response.success == 0) alert(response.error);
					else setUser(response.data);
				}
			}
		}
	}

	async function loadSettings() {
		console.log("loadSettings");
		const response = await Api.post("/settings2/get/", {
			section: ["global", "tracking"],
		});
		if (response.success == 0) alert(response.error);
		else setApiSettings(response.data);
	}

	async function loadShops() {
		console.log("loadShops");
		const response = await Api.postProtected("/tracking/shops/", {
			user_id: user.id,
		});
		if (response.success == 0) alert(response.error);
		setShops(response.rows);
		if (response.rows.length == 1) setShop(response.rows[0]);
	}

	async function loadDrivers() {
		console.log("loadDrivers");
		const request = {
			orderby: "surname,name",
			src: [],
		};
		if (shop) {
			request.src.push({
				name: "shops",
				value: shop.id,
				compare: "equal",
				type: "join",
				join_table: "drivers_shops_relations",
				join_field: "driver_id",
				src_field: "shop_id",
			});
		}
		const response = await Api.postProtected("/drivers/list/", request);
		if (response.success == 0) alert(response.error);
		setDrivers(response.rows);
	}

	async function loadOrders() {
		console.log("loadOrders");
		const res = await Api.postProtected("/tracking/orders/?debug=1", {
			shop_id: shop ? shop.id : null,
		});
		if (res.success == 0) alert(res.error);
		else setOrders(res.rows);
	}

	const generateMarkers = () => {
		let arr = [];
		if (!shop) {
			for (const shop of shops) {
				arr.push({
					title: shop.name,
					coord: shop.coord.split(","),
					icon: shopIcon(shop.id),
				});
			}
		} else {
			arr.push({
				title: shop.name,
				coord: shop.coord.split(","),
				icon: shopIcon(shop.id),
			});
		}
		for (const order of orders) {
			if (order.job_completed == 0) {
				arr.push({
					title: "Ordine N°" + order.id,
					coord: order.delivery_address.coord.split(","),
					icon: orderIcon(order.id, order.driver_id),
				});
			}
		}
		setMarkers(arr);
	};

	const generateSegments = () => {
		let arr = [];
		for (const driver of drivers) {
			let d = {
				driver_id: driver.id,
				jobs: [],
			};
			if (shop) {
				for (let job = 1; job <= 5; job++) {
					let j = {
						id: job,
						shops: [],
					};
					let s = {
						id: shop.id,
						coords: [],
					};
					const filteredOrders = orders.filter(
						(order) =>
							order.job_completed == 0 &&
							order.driver_id == driver.id &&
							order.job == job
					);
					if (filteredOrders.length > 0) {
						s.coords.push(shop.coord.split(","));
						for (const order of filteredOrders) {
							//console.log(order.id, order.driver_id, driver.id, order.driver_id == driver.id);
							s.coords.push(order.delivery_address.coord.split(","));
						}
						s.coords.push(shop.coord.split(","));
					}
					j.shops.push(s);
					d.jobs.push(j);
				}
			} else {
				for (let job = 1; job <= 5; job++) {
					let j = {
						id: job,
						shops: [],
					};
					for (const shop of shops) {
						let s = {
							id: shop.id,
							coords: [],
						};
						const filteredOrders = orders.filter(
							(order) =>
								order.job_completed == 0 &&
								order.driver_id == driver.id &&
								order.job == job &&
								order.shop_id == shop.id
						);
						if (filteredOrders.length > 0) {
							s.coords.push(shop.coord.split(","));
							for (const order of filteredOrders) {
								s.coords.push(order.delivery_address.coord.split(","));
							}
							s.coords.push(shop.coord.split(","));
						}
						j.shops.push(s);
					}
					d.jobs.push(j);
				}
			}
			arr.push(d);
		}
		console.log(arr);
		setSegments(arr);
	};

	function handleSocketEvent(type) {
		console.log("handleSocketEvent", type);
		if (type == "drivers") loadDrivers();
		if (type == "orders") loadOrders();
	}

	return (
		<>
			{apiSettings && (
				<Socket
					socket={socket}
					mandantId={apiSettings.mandant.id}
					shop={shop}
					onMessage={handleSocketEvent}
				/>
			)}
			<AppContext.Provider
				value={{
					settings,
					user,
					setUser,
					shop,
					setShop,
					setPopup,
					shops,
					setMenu,
					setSettingsVisible,
					filteredCategories,
					setFilteredCategories,
					confirm,
					setConfirm,
					apiSettings,
					config,
					setConfig,
					iframeMode,
					loadOrders,
					orders,
					drivers,
					markers,
					segments,
					map,
					setMap,
					assignmentPopUp,
					setAssignmentPopUp,
					setLoading,
				}}
			>
				{lock ? (
					<Lock />
				) : (
					<>
						{!user && <Login />}
						{user && (
							<>
								<Header />
								<div id="columns">
									<div id="map">
										<MapContainer
											center={[41.902530209055435, 12.496186584798863]}
											zoom={6}
											scrollWheelZoom={false}
											ref={setMap}
										>
											<Map />
										</MapContainer>
									</div>
									<Sidebar />
								</div>
							</>
						)}
						{menu && <Menu />}
						{settingsVisible && <Settings />}
						{assignmentPopUp && <AssignmentPopUp options={assignmentPopUp} />}
						{popup && <PopUp options={popup} />}
						<Confirm />
						{loading && <Loading />}
					</>
				)}
			</AppContext.Provider>
		</>
	);
}

export default App;
