import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import ButtonLabeled from "./ButtonLabeled";
import List from "./List";

function Header() {
	const { config, setConfig, setPopup, setMenu, shops, shop, setShop, iframeMode } =
		useContext(AppContext);

	useEffect(() => {
		if (shop) localStorage.setItem("tracking-shop", JSON.stringify(shop));
		else localStorage.removeItem("tracking-shop");
	}, [shop]);

	return (
		<div className="header">
			<div className="header-section">
				<button className="menu-button" onClick={() => setMenu(true)}>
					&equiv;
				</button>
				<ButtonLabeled
					label="Negozio"
					value={shop ? shop.name : ""}
					onClick={() =>
						setPopup({
							title: "Negozi",
							content: (
								<List
									elements={shops}
									onClick={(l) => {
										setPopup(false);
										setShop(l);
									}}
									cancelElement="Tutti"
								/>
							),
						})
					}
				/>
				<ButtonLabeled
					label="Tipo Percorso"
					value={config.line_type.name}
					onClick={() =>
						setPopup({
							title: "Tipo Percorso",
							content: (
								<List
									elements={[
										{ name: "Linea", id: "line" },
										{ name: "Percorso stradale", id: "route" },
									]}
									onClick={(el) => {
										setPopup(false);
										setConfig({ ...config, line_type: el });
									}}
								/>
							),
						})
					}
				/>
				<ButtonLabeled
					label="Tipo Mappa"
					value={config.map_tiles && config.map_tiles.name}
					onClick={() =>
						setPopup({
							title: "Tipo Mappa",
							content: (
								<List
									elements={[
										{ name: "Base", id: "base" },
										{ name: "Scala di grigi", id: "grey" },
									]}
									onClick={(el) => {
										setPopup(false);
										setConfig({ ...config, map_tiles: el });
									}}
								/>
							),
						})
					}
				/>
			</div>
			<div className="header-section">
				{iframeMode && (
					<button
						className="header-close-button"
						onClick={() => {
							window.parent.postMessage("close", "*");
						}}
					>
						&times;
					</button>
				)}
			</div>
		</div>
	);
}

export default Header;
