import L from "leaflet";

const shopIcon = (shopId) => {
	return new L.divIcon({
		iconSize: new L.Point(40, 40),
		iconAnchor: [20, 40],
		tooltipAnchor: [20, -25],
		className: "map-icon",
		html:
			'<div id="marker-shop-' +
			shopId +
			'"><span class="fa-stack"><i class="fas fa-map-marker fa-stack-2x"></i><i class="fas fa-home fa-stack-1x fa-inverse"></i></span></div>',
	});
};

export { shopIcon };
