import { useContext } from "react";
import { AppContext } from "../App";

function Menu() {
	const { setMenu, user, setSettingsVisible, setUser } = useContext(AppContext);

	return (
		<div className="menu">
			<div className="menu-header">
				<div>{user.name}</div>
				<button onClick={() => setMenu(false)}>&times;</button>
			</div>
			<div className="menu-buttons">
				{/*<button
					onClick={() => {
						setMenu(false);
						setSettingsVisible(true);
					}}
				>
					Impostazioni
				</button>*/}
				<button
					onClick={() => {
						setMenu(false);
						setUser(false);
						localStorage.removeItem("pos-user");
					}}
				>
					LogOut
				</button>
			</div>
		</div>
	);
}

export default Menu;
