import { useEffect, useState } from "react";
import Orders from "./Orders";

function Sidebar() {
	const [expanded, setExpanded] = useState(
		localStorage.getItem("tracking-sidebar-expanded") == "true"
	);

	useEffect(() => {
		setTimeout(emitResizeEvent, 300);
	}, [expanded]);

	const emitResizeEvent = () => {
		window.dispatchEvent(new Event("resize"));
	};

	return (
		<div id="sidebar" className={expanded ? "expanded" : ""}>
			<button
				className="sidebar-button"
				onClick={() => {
					setExpanded(!expanded);
					localStorage.setItem("tracking-sidebar-expanded", !expanded);
				}}
			>
				{expanded ? (
					<i className="fas fa-chevron-right" />
				) : (
					<i className="fas fa-chevron-left" />
				)}
			</button>

			<Orders status="awaiting" />
			<Orders status="delivering" />
			<Orders status="filled" />
		</div>
	);
}

export default Sidebar;
