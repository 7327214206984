import { useContext } from "react";
import { AppContext } from "../App";

function PopUp({ options, children }) {
	const { setPopup } = useContext(AppContext);
	return (
		<div className="popup">
			<div className="content">
				<div className="title">
					{options.title && <div>{options.title}</div>}
					<div className="buttons-group">
						{options.headerButton && (
							<button onClick={options.headerButton.onClick}>
								{options.headerButton.label}
							</button>
						)}
						<button
							className="close-button"
							onClick={() => {
								if (options.onClose) options.onClose();
								else setPopup(false);
							}}
						>
							&times;
						</button>
					</div>
				</div>
				{options.content && <div className="inner-content">{options.content}</div>}
				{options.fullContent && options.fullContent}
				{children && children}
				{options.footer && options.footer}
			</div>
		</div>
	);
}

export default PopUp;
