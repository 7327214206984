function ButtonLabeled({
	label,
	value,
	onClick,
	valid = true,
	disabled = false,
	size = "full",
	ellipsis = false,
}) {
	return (
		<div
			className={
				"button-labeled" +
				(!valid ? " error" : "") +
				(disabled ? " disabled" : "") +
				(size == "half" ? " half" : "") +
				(ellipsis ? " ellipsis" : "")
			}
		>
			<div className="label">{label}:</div>
			<button
				className="button button-3"
				onClick={() => {
					if (!disabled) onClick();
				}}
			>
				{value}
			</button>
		</div>
	);
}

export default ButtonLabeled;
