import logo from "./../images/logo.png";

function Lock() {
	return (
		<div className="lock">
			<div className="content">
				<img src={logo} />
				<div>Loading ...</div>
			</div>
		</div>
	);
}

export default Lock;
