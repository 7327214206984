import { useContext } from "react";
import { AppContext } from "../App";
import moment from "moment/moment";
import api from "./api/api";
import { useEffect } from "react";
import Tag from "./Tag";
import { getDistance } from "geolib";

function Order({ order, driver }) {
	const { drivers, shops, setAssignmentPopUp } = useContext(AppContext);

	useEffect(() => {
		const element = document.getElementById("order-" + order.id);
		if (!element) return;
		element.addEventListener("mouseover", higlightOrder);
		element.addEventListener("mouseout", removeHiglightOrder);

		return () => {
			element.removeEventListener("mouseover", higlightOrder);
			element.removeEventListener("mouseout", removeHiglightOrder);
		};
	}, [order]);

	const higlightOrder = () => {
		const element = document.getElementById("marker-order-" + order.id);
		if (!element) return;
		element.style.color = "red";
	};

	const removeHiglightOrder = () => {
		const element = document.getElementById("marker-order-" + order.id);
		if (!element) return;
		element.style.removeProperty("color");
	};

	const editOrder = async (request) => {
		const response = await api.postProtected("/orders/edit/" + order.id + "/", request);
		if (response.success == 0) alert(response.error);
	};

	const getOrderDistance = () => {
		if (!order.driver_id) return;
		if (!order.delivery_address.coord) return;
		const c1 = order.delivery_address.coord.split(",");
		if (!driver) return;
		if (!driver.tracking) return;
		if (!driver.tracking.lat || !driver.tracking.lon) return;
		const c2 = [driver.tracking.lat, driver.tracking.lon];
		//console.log(c1, c2);
		return " - " + (getDistance(c1, c2) / 1000).toFixed(2) + "Km";
	};

	return (
		<div id={"order-" + order.id} className="order-container">
			{order.driver_id && order.job_completed == 0 && <Tag status={order.status} />}
			<div className="order">
				<div className="info">
					<div className="title">
						N°{order.id}
						{getOrderDistance()}
					</div>
					<div className="date">
						{moment(order.delivery_date, "YYYY-MM-DD HH:mm:ss").format(
							"DD/MM/YYYY HH:mm"
						)}
					</div>
					<div className="small address">
						{order.delivery_address.address +
							" " +
							order.delivery_address.number +
							", " +
							order.delivery_address.city}
					</div>
				</div>

				{order.job_completed == 0 && (
					<div className="buttons">
						<select
							defaultValue={order.job}
							onChange={(e) => editOrder({ job: e.target.value })}
						>
							<option value={1}>Job 1</option>
							<option value={2}>Job 2</option>
							<option value={3}>Job 3</option>
							<option value={4}>Job 4</option>
							<option value={5}>Job 5</option>
						</select>
						<select
							value={order.driver_id ? order.driver_id : ""}
							onChange={(e) => {
								if (e.target.value)
									setAssignmentPopUp({ order: order, driver_id: e.target.value });
								else editOrder({ job: order.job ? order.job : 1, driver_id: null });
							}}
						>
							<option></option>
							{drivers &&
								drivers.map((driver, i) => (
									<option key={i} value={driver.id}>
										{driver.surname + " " + driver.name}
									</option>
								))}
						</select>
					</div>
				)}
			</div>
		</div>
	);
}

export default Order;
