import { useEffect } from "react";
import { useState } from "react";
import Order from "./Order";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function DragContainer({ orders, driver, onNewList }) {
	const [items, setItems] = useState([]);

	useEffect(() => {
		if (orders) setItems(orders);
	}, [orders]);

	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const newItems = reorder(items, result.source.index, result.destination.index);

		setItems(newItems);
		onNewList(newItems);
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: "none",

		// change background colour if dragging
		background: isDragging ? "lightgreen" : "white",

		// styles we need to apply on draggables
		...draggableStyle,
	});

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable">
				{(provided, snapshot) => (
					<div {...provided.droppableProps} ref={provided.innerRef}>
						{items.map((item, index) => (
							<Draggable key={item.id} draggableId={item.id} index={index}>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={getItemStyle(
											snapshot.isDragging,
											provided.draggableProps.style
										)}
									>
										<Order order={item} driver={driver} />
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}

export default DragContainer;
