function Tag({ status }) {
	const formatLabel = (status) => {
		if (status == "confirmed") return "Attesa";
		if (status == "delivering") return "Consegna";
		if (status == "filled") return "Consegnato";
	};
	const formatColor = (status) => {
		if (status == "confirmed") return "red";
		if (status == "delivering") return "green";
		if (status == "filled") return "blue";
	};

	return (
		<div className="tag" style={{ backgroundColor: formatColor(status) }}>
			<div>{formatLabel(status)}</div>
		</div>
	);
}

export default Tag;
