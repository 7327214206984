import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import FormGroup from "./form/FormGroup";
import api from "./api/api";

function Settings() {
	const { setSettingsVisible, config, setConfig } = useContext(AppContext);
	const [page, setPage] = useState("main");

	return (
		<div className="settings">
			<div className="settings-container">
				<div className="settings-header">
					<div>Impostazioni</div>
					<button onClick={() => setSettingsVisible(false)}>&times;</button>
				</div>
				<div className="settings-columns">
					<div className="settings-menu">
						<button onClick={() => setPage("main")}>Generali</button>
					</div>
					{page == "main" && (
						<div className="settings-content">
							<h2>Impostazioni Generali</h2>
							<FormGroup
								label="Modalità disegno percorso"
								name="mode"
								type="select"
								value={config.line_type}
								onChange={(e) =>
									setConfig({ ...config, line_type: e.target.value })
								}
								options={[
									{ name: "Linea", id: "line" },
									{ name: "Percorso stradale", id: "route" },
								]}
							/>
							{config.mode == "local" && (
								<FormGroup
									label="IP Server Locale"
									name="server_ip"
									type="text"
									value={config.server_ip}
									onChange={(e) =>
										setConfig({ ...config, server_ip: e.target.value })
									}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default Settings;
