import { useContext } from "react";
import { AppContext } from "../App";
import api from "./api/api";
import { useState } from "react";

function AssignmentPopUp({ options }) {
	const { drivers, setAssignmentPopUp, setLoading } = useContext(AppContext);
	const [notes, setNotes] = useState(options.order.driver_notes);
	const driver = drivers.find((driver) => driver.id == options.driver_id);
	console.log(options);
	console.log(driver);

	const editOrder = async (request) => {
		setLoading(true);
		const response = await api.postProtected("/orders/edit/" + options.order.id + "/", request);
		setLoading(false);
		if (response.success == 0) alert(response.error);
		setAssignmentPopUp(false);
	};

	return (
		<div id="assignment-popup">
			<div className="content">
				<div className="title">Assegnazione Ordine</div>
				<div className="text">
					Stai assegnando l'ordine <strong>N°{options.order.id}</strong> a{" "}
					<strong>{driver.surname + " " + driver.name}</strong>
				</div>
				Note Corriere:
				<textarea value={notes ? notes : ""} onChange={(e) => setNotes(e.target.value)} />
				<div className="buttons">
					<button onClick={() => setAssignmentPopUp(false)}>Annulla</button>
					<button
						className="primary"
						onClick={() =>
							editOrder({
								job: options.order.job ? options.order.job : 1,
								driver_id: options.driver_id,
								driver_notes: notes,
							})
						}
					>
						Assegna
					</button>
				</div>
			</div>
		</div>
	);
}

export default AssignmentPopUp;
