import { useEffect } from "react";

function Socket({ socket, mandantId, shop, onMessage }) {
	useEffect(() => {
		console.log("add socket listener");
		socket.on("connect", onConnect);
		socket.on("disconnect", onDisconnect);
		socket.on("subscribed", onSubrscribed);
		socket.on("events", onSocketEvents);

		onConnect();

		return () => {
			console.log("remove socket listener");
			socket.off("connect", onConnect);
			socket.off("disconnect", onDisconnect);
			socket.off("subscribed", onSubrscribed);
			socket.off("events", onSocketEvents);
		};
	}, [shop]);

	function onConnect() {
		console.log("Socket connected", socket.id);
		socket.emit("subscribe", "m" + mandantId + ".drivers.#");
		socket.emit(
			"subscribe",
			"m" + mandantId + "." + (shop ? "s" + shop.id : "*") + ".orders.#"
		);
	}

	function onDisconnect() {
		console.log("Socket disconnected");
	}

	function onSubrscribed(data) {
		console.log(data);
	}

	function onSocketEvents(message) {
		console.log("new socket event: ", message);
		onMessage(message.table);
	}

	return null;
}

export default Socket;
