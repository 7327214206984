function List({
	elements,
	onClick,
	label = "name",
	cancelElement = false,
	customElement = false,
	mode = 1,
}) {
	return (
		<div className={"list mode-" + mode}>
			{cancelElement && (
				<a className="list-element" onClick={() => onClick(false)}>
					{cancelElement}
				</a>
			)}
			{customElement ? (
				<>{elements && elements.map((el, i) => customElement(el, i))}</>
			) : (
				elements &&
				elements.map((el, i) => (
					<a key={i} className="list-element" onClick={() => onClick(el)}>
						{el[label]}
					</a>
				))
			)}
		</div>
	);
}

export default List;
