import { useContext, useState } from "react";
import { AppContext } from "../App";
import Order from "./Order";
import api from "./api/api";
import DragContainer from "./DragContainer";
import moment from "moment/moment";

function Orders({ status }) {
	const { orders, drivers, map, markers, shop, segments, shops } = useContext(AppContext);
	const [expanded, setExpanded] = useState(status != "filled");

	const handleNewList = (list) => {
		console.log("handleNewList", list);

		for (let i = 0; i < list.length; i++) {
			console.log("/orders/edit/" + list[i].id + "/", { delivery_order: i + 1 });
			api.postProtected("/orders/edit/" + list[i].id + "/", {
				delivery_order: i + 1,
			});
		}
	};

	const formatStatus = (status) => {
		if (status == "awaiting") return "In attesa";
		if (status == "delivering") return "In consegna";
		if (status == "filled") return "Completati";
		return status;
	};

	const filterOrders = (status, driver = false, job = false, shop = false) => {
		if (!orders) return [];
		if (status == "awaiting")
			return orders.filter((order) => !order.driver_id && order.shop_id == shop.id);
		if (status == "delivering")
			return orders.filter(
				(order) =>
					order.driver_id == driver.id &&
					order.job == job &&
					order.shop_id == shop.id &&
					order.job_completed == 0
			);
		if (status == "filled") return orders.filter((order) => order.job_completed == 1);
	};

	const getStyles = (driver) => {
		if (driver.tracking && driver.tracking.date) {
			var now = moment(new Date());
			var end = moment(driver.tracking.date, "YYYY-MM-DD HH:mm:ss");
			var duration = moment.duration(now.diff(end));
			var min = duration.asMinutes();
			if (min > 30) return { color: "red" };
			else if (min > 10) return { color: "orange" };
			else return { color: "green" };
		}
		return { color: "red" };
	};

	const isActive = (driver) => {
		let min = 100;
		if (driver.tracking && driver.tracking.date) {
			var now = moment(new Date());
			var end = moment(driver.tracking.date, "YYYY-MM-DD HH:mm:ss");
			var duration = moment.duration(now.diff(end));
			min = duration.asMinutes();
		}
		const hasOrders = orders.filter((order) => order.driver_id == driver.id).length > 0;
		if (min < 60 || hasOrders) return true;
		return false;
	};

	const zoomIn = (coordinates) => {
		console.log(coordinates);
		map.flyTo(coordinates, 18);
	};

	const zoomJob = (driver, job, shop) => {
		for (const segment of segments) {
			if (segment.driver_id == driver.id) {
				console.log(segment);
				for (const j of segment.jobs) {
					if (j.id == job) {
						console.log(j);
						for (const s of j.shops) {
							if (s.id == shop.id) {
								console.log(s);
								map.flyToBounds(s.coords);
							}
						}
					}
				}
			}
		}
	};

	const zoomOut = () => {
		if (markers.length > 1) {
			let coords = [];
			for (const marker of markers) {
				coords.push(marker.coord);
			}
			map.flyToBounds(coords);
		} else if (shop) map.flyTo(shop.coord.split(","), map.getZoom());
	};

	return (
		<div className={"orders " + status}>
			<button className="orders-header" onClick={() => setExpanded(!expanded)}>
				<h1>{formatStatus(status)}</h1>
				{expanded ? (
					<i className="fas fa-chevron-up" />
				) : (
					<i className="fas fa-chevron-down" />
				)}
			</button>
			<div className={"orders-list" + (expanded ? " expanded" : "")}>
				{status == "delivering" ? (
					drivers &&
					drivers.map(
						(driver, i) =>
							isActive(driver) && (
								<div key={i}>
									<div className="driver-title">
										<i className="fas fa-circle" style={getStyles(driver)} />
										<div>{driver.surname + " " + driver.name}</div>
										<button
											onMouseOver={() =>
												zoomIn([driver.tracking.lat, driver.tracking.lon])
											}
											onMouseOut={() => zoomOut()}
										>
											<i className="fas fa-search" />
										</button>
									</div>
									{[1, 2, 3, 4, 5].map((job, n) =>
										shops.map((shop, x) => {
											let filteredOrders = filterOrders(
												"delivering",
												driver,
												job,
												shop
											);
											if (filteredOrders.length > 0)
												return (
													<div key={n + "-" + x}>
														<div className="job-title">
															<div>
																{"Job " + job} - {shop.name}
															</div>
															<button
																onMouseOver={() =>
																	zoomJob(driver, job, shop)
																}
																onMouseOut={() => zoomOut()}
															>
																<i className="fas fa-search" />
															</button>
														</div>
														{
															<DragContainer
																onNewList={handleNewList}
																orders={filteredOrders}
																driver={driver}
															/>
														}
													</div>
												);
										})
									)}
								</div>
							)
					)
				) : (
					<>
						{status == "awaiting" &&
							shops &&
							shops.map((shop, i) => {
								let filteredOrders = filterOrders("awaiting", false, false, shop);
								if (filteredOrders.length > 0)
									return (
										<div key={i}>
											<div className="job-title">
												<div>{shop.name}</div>
											</div>
											{filterOrders("awaiting", false, false, shop).map(
												(order, i) => (
													<Order key={i} order={order} />
												)
											)}
										</div>
									);
							})}
						{status == "filled" &&
							filterOrders("filled").map((order, i) => (
								<Order key={i} order={order} />
							))}
					</>
				)}
			</div>
		</div>
	);
}

export default Orders;
