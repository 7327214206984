import L from "leaflet";

const orderIcon = (orderId, driver_id) => {
	return new L.divIcon({
		iconSize: new L.Point(40, 40),
		iconAnchor: [20, 40],
		tooltipAnchor: [20, -25],
		className: "map-icon",
		html:
			'<div id="marker-order-' +
			orderId +
			'"><span class="fa-stack" style="opacity: ' +
			(driver_id ? 0.5 : 1) +
			'"><i class="fas fa-map-marker fa-stack-2x"></i><i class="fas fa-shopping-bag fa-stack-1x fa-inverse"></i></span></div>',
	});
};

export { orderIcon };
