import { useContext, useEffect, useState } from "react";
import { TileLayer, Tooltip, Marker, useMap } from "react-leaflet";
import TextPath from "react-leaflet-textpath";
import { AppContext } from "../App";
import { userIcon } from "./icons/userIcon";
import colors from "../config/colors";
import moment from "moment/moment";

function Map() {
	const { markers, segments, drivers, shop, config, orders } = useContext(AppContext);
	const [routes, setRoutes] = useState([]);
	const map = useMap();

	useEffect(() => {
		if (markers.length > 1) {
			let coords = [];
			for (const marker of markers) {
				coords.push(marker.coord);
			}
			map.flyToBounds(coords);
		} else if (shop) map.flyTo(shop.coord.split(","), map.getZoom());
	}, [markers]);

	useEffect(() => {
		if (config.line_type.id == "route" && segments && segments.length > 0) {
			getDirections();
		}
	}, [segments, config]);

	useEffect(() => {
		console.log("routes set to", routes);
	}, [routes]);

	const getDirections = async () => {
		const arr = [];
		for (const segment of segments) {
			for (const job of segment.jobs) {
				for (const shop of job.shops) {
					if (shop.coords.length > 1) {
						const response = await fetch(
							"https://routeservice.yellgo.cloud/ors/v2/directions/driving-car/geojson",
							{
								method: "POST",
								headers: {
									"Content-Type": "application/json",
									//Authorization:"5b3ce3597851110001cf6248e4618f24f0044289a196bd36592a5c17",
								},
								body: JSON.stringify({ coordinates: arrayRevert(shop.coords) }),
							}
						);
						const json = await response.json();
						//console.log(json);
						if (!response.ok) alert(json.error.message);
						arr.push(arrayRevert(json.features[0].geometry.coordinates));
					}
				}
			}
		}
		setRoutes(arr);
	};

	const arrayRevert = (originaArr) => {
		//console.log("arrayRevert");
		//console.log(originaArr);
		const response = [];
		for (const el of originaArr) {
			response.push([el[1], el[0]]);
		}
		//console.log(response);
		return response;
	};

	const isActive = (driver) => {
		if (driver.tracking && driver.tracking.date) {
			var now = moment(new Date());
			var end = moment(driver.tracking.date, "YYYY-MM-DD HH:mm:ss");
			var duration = moment.duration(now.diff(end));
			var min = duration.asMinutes();
			const hasOrders = orders.filter((order) => order.driver_id == driver.id).length > 0;
			if (min < 60 || hasOrders) return true;
		}
		return false;
	};

	const getColor = (key) => {
		var colors = ["#6610F2", "#F17105", "#D11149", "#1A8FE3"];

		var hash = 0;
		if (key.length === 0) return hash;
		for (var i = 0; i < key.length; i++) {
			hash = key.charCodeAt(i) + ((hash << 5) - hash);
			hash = hash & hash;
		}
		hash = ((hash % colors.length) + colors.length) % colors.length;
		return colors[hash];
	};

	return (
		<>
			{!config.map_tiles || config.map_tiles.id == "base" ? (
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
			) : (
				<TileLayer
					attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
					url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
				/>
			)}
			{markers.map((marker, i) => (
				<Marker key={i} position={marker.coord} icon={marker.icon}>
					<Tooltip>{marker.title}</Tooltip>
				</Marker>
			))}
			{drivers &&
				drivers.map(
					(driver, i) =>
						driver.tracking &&
						driver.tracking.lat &&
						driver.tracking.lon &&
						isActive(driver) && (
							<Marker
								key={i}
								position={[driver.tracking.lat, driver.tracking.lon]}
								icon={userIcon(driver.id, colors[i].primary)}
							>
								<Tooltip>{driver.surname + " " + driver.name}</Tooltip>
							</Marker>
						)
				)}
			{config.line_type.id == "line"
				? segments.map((segment, i) =>
						segment.jobs.map((job, n) =>
							job.shops.map(
								(shop, x) =>
									shop.coords.length > 0 && (
										<TextPath
											key={"text-path-" + i + "-" + n}
											positions={shop.coords}
											text="►            "
											center
											repeat
											offset={7}
											pathOptions={{
												color: getColor(
													segment.driver_id + "-" + n + "-" + shop.id
												),
											}}
											attributes={{
												"font-size": 20,
												fill: getColor(
													segment.driver_id + "-" + n + "-" + shop.id
												),
											}}
										/>
									)
							)
						)
				  )
				: routes.map((segment, i) => (
						<TextPath
							key={"route-path-" + i}
							positions={segment}
							text="►            "
							center
							repeat
							offset={7}
							pathOptions={{ color: colors[i].primary }}
							attributes={{
								"font-size": 20,
								fill: colors[i].primary,
							}}
						/>
				  ))}
		</>
	);
}

export default Map;
