function FormGroup({
	type = "text",
	label,
	name,
	value,
	onChange,
	options = [],
	description,
	multiple = false,
}) {
	const handleChange = (e) => {
		if (multiple) {
			var options = e.target.options;
			var value = [];
			for (var i = 0, l = options.length; i < l; i++) {
				if (options[i].selected) {
					value.push(options[i].value);
				}
			}
			onChange(value);
		} else {
			onChange(e);
		}
	};

	return (
		<div className="form-group">
			{label && <div className="label">{label}:</div>}
			{type == "text" && (
				<input type="text" name={name} defaultValue={value} onChange={onChange} />
			)}
			{type == "textarea" && (
				<textarea name={name} defaultValue={value} onChange={onChange}></textarea>
			)}
			{type == "checkbox" && (
				<input type="checkbox" name={name} defaultChecked={value} onChange={onChange} />
			)}
			{type == "select" && (
				<select
					name={name}
					defaultValue={value}
					onChange={handleChange}
					multiple={multiple}
				>
					{options.map((el, i) => (
						<option key={i} value={el.id}>
							{el.name}
						</option>
					))}
				</select>
			)}
			{description && <div className="description">{description}</div>}
		</div>
	);
}

export default FormGroup;
